import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  },
  subtext:{
    paddingTop: `1rem`,
  },
}

export default () => (
  <>
    <Section>
     <h1> 
      Lose pounds effectively and naturally! *
    </h1>
      <Heading variant='h3' sx={styles.running}>
        Lose pounds effectively and naturally *
WITHOUT pills, prepared foods and heavy exercise. Follow the program as directed and lose 5-10% of your body weight in the first 10 days.*
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      <Button as={Link} to='https://docmein.com/app/page/77293722292565792/secure/home'>
        Start Loosing Weight!
      </Button>
      <Button variant='white' as={Link} to='/the-sadkhin-method'>
        Learn more
      </Button>
      <Heading variant='h4' sx={styles.subtext}>Book your free consultation at our Boston office online.</Heading>
      <Heading variant='h3' sx={styles.subtext}>Call us at: (617) 277-8844</Heading>
     </Box>
     <div>There is an alternative to phentermine and other drugs, 
      as well as surgical treatment of obesity and #weightloss for better shape. 
      It is much safer and more effective without any side effects. 
      No grueling exercises. Acupressure and individual eating plan with natural, 
      fresh, healthy food and delicious recipes.
      </div>
  </>
)
